"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var burger_component_1 = require("@components/burger.component");
var configurator_component_1 = require("@components/configurator.component");
var instagram_component_1 = require("@components/instagram.component");
var locale_component_1 = require("@components/locale.component");
var overlay_component_1 = require("@components/overlay.component");
var search_component_1 = require("@components/search.component");
var download_component_1 = require("@components/download.component");
var filter_component_1 = require("@components/filter.component");
var merchant_component_1 = require("@components/merchant.component");
var popup_component_1 = require("@components/popup.component");
var sidebar_component_1 = require("@components/sidebar.component");
var slider_component_1 = require("@components/slider.component");
var video_image_slider_component_1 = require("@components/video-image-slider.component");
var icon_service_1 = require("@services/icon.service");
var SmoothScroll = require("smooth-scroll");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var sameheight_service_1 = require("@services/sameheight.service");
require('lazysizes');
require('lazysizes/plugins/rias/ls.rias');
require('lazysizes/plugins/bgset/ls.bgset');
require('bootstrap/js/dist/collapse');
require('bootstrap/js/dist/modal');
new overlay_component_1.OverlayComponent();
new icon_service_1.IconService().replaceIcons();
new locale_component_1.LocaleComponent();
new burger_component_1.BurgerComponent();
new SmoothScroll('a[data-smoothscroll]');
new sidebar_component_1.SidebarComponent();
new search_component_1.SearchComponent();
new download_component_1.DownloadComponent();
new filter_component_1.FilterComponent();
new merchant_component_1.MerchantComponent();
new popup_component_1.PopupComponent();
domhelpers_shared_1.$each('.slider', function ($slider) { return new slider_component_1.SliderComponent($slider); });
domhelpers_shared_1.$each('.video-image-slider', function ($videoImageSlider) { return new video_image_slider_component_1.VideoImageSliderComponent($videoImageSlider); });
domhelpers_shared_1.$each('.configurator', function ($configurator) { return new configurator_component_1.ConfiguratorComponent($configurator); });
domhelpers_shared_1.$each('.instagram', function ($instagram) { return new instagram_component_1.InstagramComponent($instagram); });
window.addEventListener('load', function () {
    var sameHeightService = new sameheight_service_1.SameHeightService();
    domhelpers_shared_1.$each('.tile-teaser, .news-teaser, .main__newsletter', function ($el) { return sameHeightService.findElements($el); });
    sameHeightService.triggerRender();
});

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("redux");
var rxjs_1 = require("rxjs");
var Configurator = (function () {
    function Configurator() {
        var _this = this;
        this.store$ = new rxjs_1.ReplaySubject(1);
        this.store = redux_1.createStore(this.reducer.bind(this));
        this.store$.next(this.store.getState());
        this.store.subscribe(function () { return _this.store$.next(_this.store.getState()); });
    }
    Configurator.prototype.reducer = function (configurator, action) {
        if (configurator === void 0) { configurator = Configurator.INITIAL_CONFIGURATOR; }
        switch (action.type) {
            case 'SET_LW_WHEEL_TYPE': return Object.assign({}, Configurator.INITIAL_CONFIGURATOR, {
                lwWheelType: [0, 1].indexOf(action.lwWheelType) !== -1 ? action.lwWheelType : Configurator.INITIAL_CONFIGURATOR.lwWheelType
            });
            case 'SET_LW_VARIANT': return Object.assign({}, Configurator.INITIAL_CONFIGURATOR, {
                lwWheelType: configurator.lwWheelType,
                lwVariant: ['tubular', 'clincher'].indexOf(action.lwVariant) !== -1 ? action.lwVariant : Configurator.INITIAL_CONFIGURATOR.lwVariant
            });
            case 'SET_LW_SWITCHING_SYSTEM': return Object.assign({}, Configurator.INITIAL_CONFIGURATOR, {
                lwWheelType: configurator.lwWheelType,
                lwVariant: configurator.lwVariant,
                lwSwitchingSystem: [20, 24].indexOf(action.lwSwitchingSystem) !== -1 ? action.lwSwitchingSystem : Configurator.INITIAL_CONFIGURATOR.lwSwitchingSystem
            });
            case 'SET_LW_LABEL': return Object.assign({}, Configurator.INITIAL_CONFIGURATOR, {
                lwWheelType: configurator.lwWheelType,
                lwVariant: configurator.lwVariant,
                lwSwitchingSystem: configurator.lwSwitchingSystem,
                lwLabel: [63, 85].indexOf(action.lwLabel) !== -1 ? action.lwLabel : Configurator.INITIAL_CONFIGURATOR.lwLabel
            });
            case 'SET_LW_AXLE_SYSTEM': return Object.assign({}, Configurator.INITIAL_CONFIGURATOR, {
                lwWheelType: configurator.lwWheelType,
                lwVariant: configurator.lwVariant,
                lwSwitchingSystem: configurator.lwSwitchingSystem,
                lwLabel: configurator.lwLabel,
                lwAxelSystem: [8, 16, 20].indexOf(action.lwAxelSystem) !== -1 ? action.lwAxelSystem : Configurator.INITIAL_CONFIGURATOR.lwAxelSystem
            });
            case 'SET_LW_NO_OF_SPOKES': return __assign({}, configurator, { lwNoOfSpikes: ['standard', 'black edition', 'white edition'].indexOf(action.lwNoOfSpikes) !== -1 ? action.lwNoOfSpikes : Configurator.INITIAL_CONFIGURATOR.lwNoOfSpikes });
            default: return configurator;
        }
    };
    Configurator.prototype.getStore = function () {
        return this.store;
    };
    Configurator.prototype.getStoreAsReplaySubject = function () {
        return this.store$;
    };
    Configurator.INITIAL_CONFIGURATOR = {
        lwWheelType: null,
        lwVariant: null,
        lwSwitchingSystem: null,
        lwLabel: null,
        lwAxelSystem: null,
        lwNoOfSpikes: null
    };
    return Configurator;
}());
exports.Configurator = Configurator;
exports.ConfiguratorTypes = ['lwWheelType', 'lwVariant', 'lwSwitchingSystem', 'lwLabel', 'lwAxelSystem', 'lwNoOfSpikes'];

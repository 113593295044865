"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var gumshoe = require("gumshoejs");
var Sticky = require("sticky-js");
var SidebarComponent = (function () {
    function SidebarComponent() {
        if (SidebarComponent.instance) {
            return SidebarComponent.instance;
        }
        SidebarComponent.instance = this;
        new Sticky('.sidebar');
        gumshoe.init({
            selector: '.sidebar__anchors-link',
            selectorHeader: '.sidebar',
            activeClass: 'active'
        });
    }
    return SidebarComponent;
}());
exports.SidebarComponent = SidebarComponent;

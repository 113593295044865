"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Handlebars = require("handlebars/runtime");
var lodash_es_1 = require("lodash-es");
Handlebars.registerHelper('capture', function () {
    if (arguments.length === 2) {
        lodash_es_1.set(this, arguments[0], arguments[1].fn(this));
    }
    if (arguments.length === 3) {
        lodash_es_1.set(arguments[1], arguments[0], arguments[2].fn(this));
    }
    return null;
});
Handlebars.registerHelper('math', function (lvalue, operator, rvalue) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);
    return {
        '+': lvalue + rvalue,
        '-': lvalue - rvalue,
        '*': lvalue * rvalue,
        '/': lvalue / rvalue,
        '%': lvalue % rvalue
    }[operator];
});
var reduceOp = function (args, reducer) {
    args = Array.from(args);
    args.pop();
    var first = args.shift();
    return args.reduce(reducer, first);
};
Handlebars.registerHelper({
    eq: function () { return reduceOp(arguments, function (a, b) { return a === b; }); },
    ne: function () { return reduceOp(arguments, function (a, b) { return a !== b; }); },
    lt: function () { return reduceOp(arguments, function (a, b) { return a < b; }); },
    gt: function () { return reduceOp(arguments, function (a, b) { return a > b; }); },
    lte: function () { return reduceOp(arguments, function (a, b) { return a <= b; }); },
    gte: function () { return reduceOp(arguments, function (a, b) { return a >= b; }); },
    and: function () { return reduceOp(arguments, function (a, b) { return a && b; }); },
    or: function () { return reduceOp(arguments, function (a, b) { return a || b; }); },
});
Handlebars.registerHelper('toJSON', function (object) {
    return JSON.stringify(object);
});
Handlebars.registerHelper('times', function (n, block) {
    var accum = '';
    for (var i = 0; i < n; ++i) {
        block.data.index = i;
        block.data.first = i === 0;
        block.data.last = i === (n - 1);
        accum += block.fn(this);
    }
    return accum;
});
Handlebars.registerHelper('concat', function () {
    var args = arguments;
    return Object.keys(args).map(function (key) { return typeof args[key] !== 'object' ? args[key] : ''; }).join('');
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bodyclick_service_1 = require("@services/bodyclick.service");
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var LocaleComponent = (function () {
    function LocaleComponent() {
        this.LOCALE_SHOW_CLASS = 'locale--show';
        this.bodyClickService = new bodyclick_service_1.BodyClickService();
        if (LocaleComponent.instance) {
            return LocaleComponent.instance;
        }
        LocaleComponent.instance = this;
        this.$locale = domhelpers_shared_1.$('.locale');
        if (this.$locale !== null) {
            this.delegateEvents();
        }
    }
    LocaleComponent.prototype.delegateEvents = function () {
        this.localeTrigger$ = delegatedevent_shared_1.delegatedEventProxy(this.$locale, 'click', '.button');
        this.localeTrigger$.subscribe(this.handleLocaleTriggerClick.bind(this));
        this.pseudoChange$ = delegatedevent_shared_1.delegatedEventProxy(this.$locale, 'change', '.locale__pseudo');
        this.pseudoChange$.subscribe(this.handlePseudoChange.bind(this));
        this.bodyClickService.outsideClick(this.$locale, this.handlyBodyClick.bind(this));
    };
    LocaleComponent.prototype.handleLocaleTriggerClick = function (e) {
        e.event.preventDefault();
        this.$locale.classList.contains(this.LOCALE_SHOW_CLASS)
            ? this.$locale.classList.remove(this.LOCALE_SHOW_CLASS)
            : this.$locale.classList.add(this.LOCALE_SHOW_CLASS);
    };
    LocaleComponent.prototype.handlePseudoChange = function (e) {
        if (e.$delegate.value !== '#') {
            window.location.href = e.$delegate.value;
        }
    };
    LocaleComponent.prototype.getLocaleTrigger = function () {
        return this.localeTrigger$;
    };
    LocaleComponent.prototype.getPseudoChange = function () {
        return this.pseudoChange$;
    };
    LocaleComponent.prototype.handlyBodyClick = function (e) {
        this.$locale.classList.remove(this.LOCALE_SHOW_CLASS);
    };
    return LocaleComponent;
}());
exports.LocaleComponent = LocaleComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var DownloadComponent = (function () {
    function DownloadComponent() {
        if (DownloadComponent.instance) {
            return DownloadComponent.instance;
        }
        DownloadComponent.instance = this;
        this.$filter = domhelpers_shared_1.$('.main__download');
        this.$no_results = domhelpers_shared_1.$('#downloads_no_results');
        if (this.$filter !== null) {
            this.delegateEvents();
        }
    }
    DownloadComponent.prototype.delegateEvents = function () {
        this.change$ = delegatedevent_shared_1.delegatedEventProxy(this.$filter, 'change', '#select__field__downloads', { capture: true });
        this.change$.subscribe(this.handleChange.bind(this));
    };
    DownloadComponent.prototype.handleChange = function (e) {
        var value = e.$delegate.value;
        var $elements = domhelpers_shared_1.$$('.download-element');
        var $elements_filtered = domhelpers_shared_1.$$('.download-element-' + value);
        if (value) {
            $elements.forEach(function ($item) { return $item.classList.add('d-none'); });
            if ($elements_filtered && $elements_filtered.length) {
                $elements_filtered.forEach(function ($item) { return $item.classList.add('d-none'); });
                domhelpers_shared_1.$('.download-element-' + value).classList.remove('d-none');
                domhelpers_shared_1.$('#downloads_no_results').classList.remove('d-block');
            }
            else {
                domhelpers_shared_1.$('#downloads_no_results').classList.add('d-block');
            }
        }
        else {
            domhelpers_shared_1.$('#downloads_no_results').classList.remove('d-block');
            $elements.forEach(function ($item) { return $item.classList.remove('d-none'); });
        }
    };
    return DownloadComponent;
}());
exports.DownloadComponent = DownloadComponent;

var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias2,(helpers.eq||(depth0 && depth0.eq)||container.hooks.helperMissing).call(alias2,(depth0 != null ? depth0.type : depth0),"image",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <div class=\"col col-10 col-md"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                    <a class=\"instagram__link\" href=\""
    + alias3(alias2((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\"\n                        style=\"background-image:url('"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.images : depth0)) != null ? stack1.low_resolution : stack1)) != null ? stack1.url : stack1), depth0))
    + "');\">\n                        "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.caption : depth0)) != null ? stack1.text : stack1), depth0))
    + "\n                    </a>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " mrg-b-5 mrg-md-b-0";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "<div class=\"instagram__wrapper container\">\n    <div class=\"row flex-column flex-md-row flex-md-nowrap\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.data : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});
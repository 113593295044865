"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var breakpointchange_service_1 = require("@services/breakpointchange.service");
var overlay_service_1 = require("@services/overlay.service");
var windowresize_service_1 = require("@services/windowresize.service");
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var BurgerComponent = (function () {
    function BurgerComponent() {
        var _this = this;
        this.FLYOUT_VISIBLE_CLASS = 'burger--flyout-visible';
        this.ITEM_ACTIVE_CLASS = 'burger__item--active';
        this.ITEM_HASCHILDREN_CLASS = 'burger__item--haschildren';
        this.FLYOUT_WHEREAMI_CLASS = 'burger--whereami';
        this.POSSIBLE_LEVELS = [2, 3];
        this.POSSIBLE_ACTIVES = [2, 3, 4];
        this.FORCED_VISIBILITY_CLASS = 'burger--forced-visibility';
        this.HIDE_WRAPPER_CLASS = 'burger--hide-wrapper';
        this.SMALL_BREAKPOINTS = ['md', 'sm', 'xs'];
        this.calculateHeight$ = new rxjs_1.Subject();
        this.breakpointChange$ = new breakpointchange_service_1.BreakpointChangeService().getBehaviorSubject();
        this.resize$ = new windowresize_service_1.WindowResizeService().getResizeObservable();
        this.overlayService = new overlay_service_1.OverlayService();
        this.timeout = null;
        this.TIMEOUT_MS = 2000;
        if (BurgerComponent.instance) {
            return BurgerComponent.instance;
        }
        BurgerComponent.instance = this;
        this.$burger = domhelpers_shared_1.$('.burger');
        if (this.$burger !== null) {
            this.$wrapper = domhelpers_shared_1.$('.burger__flyout-wrapper', this.$burger);
            if (this.$wrapper !== null) {
                delegatedevent_shared_1.delegatedEventProxy(this.$burger, 'transitionend', '.burger__flyout--level1').subscribe(function (e) {
                    if (e.event.propertyName === 'width') {
                        if (_this.$burger.classList.contains(_this.FORCED_VISIBILITY_CLASS)) {
                            if (_this.timeout !== null) {
                                window.clearTimeout(_this.timeout);
                                _this.timeout = null;
                            }
                            _this.$wrapper.style.height = domhelpers_shared_1.$$('.burger__flyout', _this.$burger).reduce(function (a, $b) { return Math.max(a, $b.scrollHeight); }, 0) / 10 + "rem";
                            _this.$burger.classList.remove(_this.FORCED_VISIBILITY_CLASS);
                            if (_this.$burger.classList.contains(_this.FLYOUT_WHEREAMI_CLASS)) {
                                _this.$burger.classList.remove(_this.HIDE_WRAPPER_CLASS);
                            }
                        }
                        else if (_this.$burger.classList.contains(_this.HIDE_WRAPPER_CLASS)) {
                            if (_this.timeout !== null) {
                                window.clearTimeout(_this.timeout);
                                _this.timeout = null;
                            }
                            _this.$burger.classList.remove(_this.HIDE_WRAPPER_CLASS);
                        }
                    }
                });
                this.calculateHeight$.pipe(operators_1.withLatestFrom(this.breakpointChange$)).subscribe(this.calculateHeight.bind(this));
                this.calculateHeight$.next();
                this.resize$.subscribe(function (_) { return _this.calculateHeight$.next(); });
                this.delegateEvents();
            }
        }
    }
    BurgerComponent.prototype.calculateHeight = function (_a) {
        var _this = this;
        var _ = _a[0], breakpoint = _a[1];
        if (this.SMALL_BREAKPOINTS.indexOf(breakpoint) !== -1) {
            this.$burger.classList.remove(this.FLYOUT_WHEREAMI_CLASS);
        }
        this.$burger.classList.add(this.HIDE_WRAPPER_CLASS);
        this.$burger.classList.add(this.FORCED_VISIBILITY_CLASS);
        if (this.timeout !== null) {
            window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(function () {
            _this.timeout = null;
            _this.$wrapper.style.height = domhelpers_shared_1.$$('.burger__flyout', _this.$burger).reduce(function (a, $b) { return Math.max(a, $b.scrollHeight); }, 0) / 10 + "rem";
            _this.$burger.classList.remove(_this.FORCED_VISIBILITY_CLASS);
            window.setTimeout(function () {
                _this.$burger.classList.remove(_this.HIDE_WRAPPER_CLASS);
            }, _this.TIMEOUT_MS);
        }, this.TIMEOUT_MS);
    };
    BurgerComponent.prototype.delegateEvents = function () {
        this.burgerTrigger$ = delegatedevent_shared_1.delegatedEventProxy(this.$burger, 'click', '.burger__item--burger .burger__link');
        this.burgerTrigger$.subscribe(this.handleBurgerTriggerClick.bind(this));
        this.flyoutLinkTrigger$ = delegatedevent_shared_1.delegatedEventProxy(this.$burger, 'click', '.burger__flyout-wrapper .burger__link');
        this.flyoutLinkTrigger$.pipe(operators_1.withLatestFrom(this.breakpointChange$)).subscribe(this.handleFlyoutLinkTriggerClick.bind(this));
        this.flyoutBackTrigger$ = delegatedevent_shared_1.delegatedEventProxy(this.$burger, 'click', '.burger__flyout-wrapper .burger__item--back button');
        this.flyoutBackTrigger$.subscribe(this.handleFlyoutBackTriggerClick.bind(this));
        this.flyoutWhereAmITrigger$ = delegatedevent_shared_1.delegatedEventProxy(this.$burger, 'click', '.burger__flyout-wrapper .burger__item--whereami button');
        this.flyoutWhereAmITrigger$.subscribe(this.handleFlyoutWhereAmITriggerClick.bind(this));
        this.flyoutClosePathTrigger$ = delegatedevent_shared_1.delegatedEventProxy(this.$burger, 'click', '.burger__flyout-wrapper .burger__item--closepath button');
        this.flyoutClosePathTrigger$.subscribe(this.handleFlyoutClosePathTriggerClick.bind(this));
    };
    BurgerComponent.prototype.handleBurgerTriggerClick = function (e) {
        e.event.preventDefault();
        if (this.$burger.classList.contains(this.FLYOUT_VISIBLE_CLASS)) {
            this.$burger.classList.remove(this.FLYOUT_VISIBLE_CLASS);
            this.overlayService.setActive('flyout', false);
        }
        else {
            this.$burger.classList.add(this.FLYOUT_VISIBLE_CLASS);
            this.overlayService.setActive('flyout', true);
        }
    };
    BurgerComponent.prototype.handleFlyoutLinkTriggerClick = function (_a) {
        var _this = this;
        var e = _a[0], breakpoint = _a[1];
        var $currentItem = e.$delegate.closest('.burger__item');
        if ($currentItem !== null) {
            var $flyout = $currentItem.closest('.burger__flyout');
            if ($flyout !== null) {
                domhelpers_shared_1.$each('.burger__item', function ($item) { return $item.classList.remove(_this.ITEM_ACTIVE_CLASS); }, $flyout);
                $currentItem.classList.add(this.ITEM_ACTIVE_CLASS);
                var levelChunks = $flyout.className.split('burger__flyout--level');
                var newLevel = levelChunks.length > 1 ? ~~levelChunks[1].substr(0, 1) + 1 : null;
                var hasTeaser = domhelpers_shared_1.$('.burger__teaser', $currentItem) !== null;
                if (newLevel !== null) {
                    if (this.POSSIBLE_ACTIVES.indexOf(newLevel) !== -1
                        && (newLevel !== this.POSSIBLE_ACTIVES.slice(-1)[0]
                            || hasTeaser === true)) {
                        this.POSSIBLE_ACTIVES.forEach(function (level) { return _this.$burger.classList.remove("burger--item-active" + level); });
                        this.POSSIBLE_ACTIVES.forEach(function (level) { return _this.$burger.classList.remove("burger--item-teaser-active"); });
                        this.$burger.classList.add("burger--item-active" + newLevel);
                        if (newLevel === 3 && hasTeaser)
                            this.$burger.classList.add("burger--item-teaser-active");
                    }
                }
                if ($currentItem.classList.contains(this.ITEM_HASCHILDREN_CLASS) === true) {
                    e.event.preventDefault();
                    if (newLevel !== null) {
                        if (this.POSSIBLE_LEVELS.indexOf(newLevel) !== -1) {
                            this.POSSIBLE_LEVELS.forEach(function (level) { return _this.$burger.classList.remove("burger--show-level" + level); });
                            this.$burger.classList.add("burger--show-level" + newLevel);
                        }
                    }
                }
                else if (this.SMALL_BREAKPOINTS.indexOf(breakpoint) === -1
                    && hasTeaser === true) {
                    e.event.preventDefault();
                }
            }
        }
    };
    BurgerComponent.prototype.handleFlyoutBackTriggerClick = function (e) {
        var _this = this;
        e.event.preventDefault();
        var $currentItem = e.$delegate.closest('.burger__item');
        if ($currentItem !== null) {
            var $flyout = $currentItem.closest('.burger__flyout');
            if ($flyout !== null) {
                domhelpers_shared_1.$each('.burger__item', function ($item) { return $item.classList.remove(_this.ITEM_ACTIVE_CLASS); }, $flyout);
                var levelChunks = $flyout.className.split('burger__flyout--level');
                if (levelChunks.length > 1) {
                    var newLevel = ~~levelChunks[1].substr(0, 1) - 1;
                    this.POSSIBLE_ACTIVES.forEach(function (level) { return _this.$burger.classList.remove("burger--item-active" + level); });
                    this.POSSIBLE_ACTIVES.forEach(function (level) { return _this.$burger.classList.remove("burger--item-teaser-active"); });
                    if (this.POSSIBLE_ACTIVES.indexOf(newLevel) !== -1) {
                        this.$burger.classList.add("burger--item-active" + newLevel);
                    }
                    this.POSSIBLE_LEVELS.forEach(function (level) { return _this.$burger.classList.remove("burger--show-level" + level); });
                    if (this.POSSIBLE_LEVELS.indexOf(newLevel) !== -1) {
                        this.$burger.classList.add("burger--show-level" + newLevel);
                    }
                }
            }
        }
    };
    BurgerComponent.prototype.handleFlyoutWhereAmITriggerClick = function (e) {
        e.event.preventDefault();
        this.$burger.classList.add(this.FLYOUT_WHEREAMI_CLASS);
    };
    BurgerComponent.prototype.handleFlyoutClosePathTriggerClick = function (e) {
        e.event.preventDefault();
        this.$burger.classList.remove(this.FLYOUT_WHEREAMI_CLASS);
    };
    BurgerComponent.prototype.getBurgerTrigger = function () {
        return this.burgerTrigger$;
    };
    BurgerComponent.prototype.getFlyoutLinkTrigger = function () {
        return this.flyoutLinkTrigger$;
    };
    BurgerComponent.prototype.getFlyoutBackTrigger = function () {
        return this.flyoutBackTrigger$;
    };
    BurgerComponent.prototype.getFlyoutWhereAmITrigger = function () {
        return this.flyoutWhereAmITrigger$;
    };
    BurgerComponent.prototype.getFlyoutClosePathTrigger = function () {
        return this.flyoutClosePathTrigger$;
    };
    return BurgerComponent;
}());
exports.BurgerComponent = BurgerComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
function delegatedEventProxy($parent, type, selector, eventOptions) {
    if (eventOptions === void 0) { eventOptions = {}; }
    return rxjs_1.fromEvent($parent, type, eventOptions, function (e) {
        return { event: e, $delegate: e.target.closest(selector) };
    }).pipe(operators_1.filter(function (e) { return e.$delegate !== null; }));
}
exports.delegatedEventProxy = delegatedEventProxy;

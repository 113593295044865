"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var IconService = (function () {
    function IconService() {
        this.icons = {
            'arrow-down': require('../../images/arrow-down.svg'),
            'back': require('../../images/back.svg'),
            'burger': require('../../images/burger.svg'),
            'close': require('../../images/close.svg'),
            'dots': require('../../images/dots.svg'),
            'download': require('../../images/download.svg'),
            'facebook': require('../../images/facebook.svg'),
            'instagram': require('../../images/instagram.svg'),
            'logo': require('../../images/logo.svg'),
            'plus': require('../../images/plus.svg'),
            'poi': require('../../images/poi.svg'),
            'twitter': require('../../images/twitter.svg'),
            'youtube': require('../../images/youtube.svg')
        };
        if (IconService.instance) {
            return IconService.instance;
        }
        IconService.instance = this;
    }
    IconService.prototype.replaceIcons = function ($el) {
        var _this = this;
        if ($el === void 0) { $el = document.body; }
        domhelpers_shared_1.$each('.icon:not(svg)', function ($el2) {
            var classNames = Array.from($el2.classList);
            var className = classNames.find(function (a) { return a.indexOf('icon--') !== -1; });
            if (typeof className !== 'undefined') {
                var id = className.substr(6);
                if (_this.hasIcon(id)) {
                    var $svg_1 = new DOMParser().parseFromString(_this.getIcon(id), 'image/svg+xml');
                    ['id', 'class', 'style', 'title', 'tabindex', 'accesskey'].forEach(function (attrName) {
                        var attrValue = $el2.getAttribute(attrName);
                        if (attrValue !== null) {
                            $svg_1.documentElement.setAttribute(attrName, attrValue);
                        }
                    });
                    $el2.parentNode.replaceChild($svg_1.documentElement, $el2);
                }
            }
        }, $el);
        return this;
    };
    IconService.prototype.hasIcon = function (id) {
        return this.icons.hasOwnProperty(id);
    };
    IconService.prototype.getIcon = function (id) {
        return this.hasIcon(id) ? this.icons[id] : false;
    };
    IconService.prototype.setIcon = function (id, content, overwrite) {
        if (overwrite === void 0) { overwrite = true; }
        if (this.hasIcon(id) && overwrite === false) {
            return;
        }
        this.icons[id] = content;
        return this;
    };
    IconService.prototype.setIcons = function (obj, overwrite) {
        var _this = this;
        if (overwrite === void 0) { overwrite = true; }
        Object.keys(obj).forEach(function (id) { return _this.setIcon(id, obj[id], overwrite); });
        return this;
    };
    IconService.prototype.removeAllIcons = function () {
        this.icons = {};
        return this;
    };
    return IconService;
}());
exports.IconService = IconService;

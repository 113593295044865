"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var breakpointchange_service_1 = require("./breakpointchange.service");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var windowresize_service_1 = require("@services/windowresize.service");
var SameHeightService = (function () {
    function SameHeightService() {
        this.sameHeights = {};
        this.breakpointChange$ = new breakpointchange_service_1.BreakpointChangeService().getBehaviorSubject();
        this.resize$ = new windowresize_service_1.WindowResizeService().getResizeObservable();
        this.triggerRender$ = new rxjs_1.Subject();
        if (SameHeightService.instance) {
            return SameHeightService.instance;
        }
        SameHeightService.instance = this;
        this.resize$.pipe(operators_1.withLatestFrom(this.breakpointChange$)).subscribe(this.setSameHeight.bind(this));
        this.triggerRender$.pipe(operators_1.withLatestFrom(this.breakpointChange$)).subscribe(this.setSameHeight.bind(this));
    }
    SameHeightService.prototype.findElements = function ($el) {
        var _this = this;
        if ($el === void 0) { $el = document.body; }
        domhelpers_shared_1.$each('[data-same-height]', function ($el2) {
            var attr = $el2.getAttribute('data-same-height');
            if (!_this.sameHeights.hasOwnProperty(attr)) {
                _this.sameHeights[attr] = {
                    elements: [],
                    breakpoints: attr.indexOf('@') === -1
                        ? false
                        : attr
                            .split('@')
                            .slice(1, 2)
                            .join('')
                            .split(',')
                };
            }
            _this.sameHeights[attr].elements.push($el2);
        }, $el);
    };
    SameHeightService.prototype.setSameHeight = function (_a) {
        var _this = this;
        var _ = _a[0], breakpoint = _a[1];
        Object.keys(this.sameHeights).forEach(function (key) {
            if (_this.sameHeights[key].elements.length > 1) {
                _this.sameHeights[key].elements.forEach(function ($a) {
                    $a.style.height = 'auto';
                });
                if (_this.sameHeights[key].breakpoints === false
                    || _this.sameHeights[key].breakpoints.indexOf(breakpoint) !== -1) {
                    var max_1 = _this.getMaxHeight(key);
                    _this.sameHeights[key].elements.forEach(function ($a) {
                        $a.style.height = max_1 + 'px';
                    });
                }
            }
        });
    };
    SameHeightService.prototype.getMaxHeight = function (key) {
        return Math.max.apply(Math, this.sameHeights[key].elements.map(function ($a) { return $a.scrollHeight; }).concat([0]));
    };
    SameHeightService.prototype.triggerRender = function () {
        this.triggerRender$.next();
    };
    SameHeightService.prototype.getTriggerRenderSubject = function () {
        return this.triggerRender$;
    };
    return SameHeightService;
}());
exports.SameHeightService = SameHeightService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function $(selector, $parent) {
    if ($parent === void 0) { $parent = document; }
    return $parent.querySelector(selector);
}
exports.$ = $;
function $$(selector, $parent) {
    if ($parent === void 0) { $parent = document; }
    return Array.from($parent.querySelectorAll(selector));
}
exports.$$ = $$;
function $id(id) {
    return document.getElementById(id);
}
exports.$id = $id;
function $cls(className, $parent) {
    if ($parent === void 0) { $parent = document; }
    return Array.from($parent.getElementsByClassName(className));
}
exports.$cls = $cls;
function $each(selector, callback, $parent) {
    if ($parent === void 0) { $parent = document; }
    var $els = $$(selector, $parent);
    if ($els !== null) {
        $els.forEach(callback);
    }
}
exports.$each = $each;
function $remove($el) {
    $el.parentNode.removeChild($el);
}
exports.$remove = $remove;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var PopupComponent = (function () {
    function PopupComponent() {
        var _this = this;
        this.timeout = 8000;
        if (PopupComponent.instance) {
            return PopupComponent.instance;
        }
        PopupComponent.instance = this;
        this.$popup = domhelpers_shared_1.$('.popup-banner');
        this.$close = domhelpers_shared_1.$('.popup-banner .icon-container');
        this.$button = domhelpers_shared_1.$('.popup-banner .button');
        if (this.$popup !== null) {
            var cookie = this.getCookie('popupSeen');
            if (!cookie) {
                window.setTimeout(function () {
                    _this.$popup.classList.add('active');
                    _this.$close.addEventListener('click', function () { return _this.handleClick(); });
                    _this.$button.addEventListener('click', function () { return _this.handleClick(); });
                }, this.timeout);
            }
        }
    }
    PopupComponent.prototype.handleClick = function () {
        this.setCookie('popupSeen', true, 14);
        this.$popup.classList.remove('active');
    };
    PopupComponent.prototype.setCookie = function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };
    PopupComponent.prototype.getCookie = function (cookieName) {
        var name = cookieName + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return true;
            }
        }
        return false;
    };
    return PopupComponent;
}());
exports.PopupComponent = PopupComponent;

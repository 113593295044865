"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var configurator_shared_1 = require("@shared/configurator.shared");
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
require("@shared/handlebars.shared");
var morphdom = require('morphdom');
var uuidv4 = require('uuid/v4');
var ConfiguratorComponent = (function () {
    function ConfiguratorComponent($configurator) {
        var _this = this;
        this.template = require('../../_patterns/02-organisms/00-global/_06-configurator-frontend.hbs');
        this.configurator = new configurator_shared_1.Configurator();
        this.configuratorStore = this.configurator.getStore();
        this.configuratorStore$ = this.configurator.getStoreAsReplaySubject();
        this.data = null;
        this.uuid = uuidv4();
        this.LOADING_CLASS = 'configurator--loading';
        this.behaviors = [
            {
                event: 'input',
                selector: "#configurator-" + this.uuid + "--lw-wheel-type",
                pipe: null,
                action: function (e) { return _this.configuratorStore.dispatch({
                    type: 'SET_LW_WHEEL_TYPE',
                    disc: parseInt(e.$delegate.value)
                }); }
            },
            {
                event: 'input',
                selector: "#configurator-" + this.uuid + "--lw-variant",
                pipe: null,
                action: function (e) { return _this.configuratorStore.dispatch({
                    type: 'SET_LW_VARIANT',
                    rimType: e.$delegate.value
                }); }
            },
            {
                event: 'input',
                selector: "#configurator-" + this.uuid + "--lw-switching-system",
                pipe: null,
                action: function (e) { return _this.configuratorStore.dispatch({
                    type: 'SET_LW_SWITCHING_SYSTEM',
                    rimWidth: parseInt(e.$delegate.value)
                }); }
            },
            {
                event: 'input',
                selector: "#configurator-" + this.uuid + "--lw_label",
                pipe: null,
                action: function (e) { return _this.configuratorStore.dispatch({
                    type: 'SET_LW_LABEL',
                    rimHeight: parseInt(e.$delegate.value)
                }); }
            },
            {
                event: 'input',
                selector: "#configurator-" + this.uuid + "--lw_axle_system",
                pipe: null,
                action: function (e) { return _this.configuratorStore.dispatch({
                    type: 'SET_LW_AXLE_SYSTEM',
                    spokes: parseInt(e.$delegate.value)
                }); }
            },
            {
                event: 'input',
                selector: "#configurator-" + this.uuid + "--LW_NO_OF_SPIKES",
                pipe: null,
                action: function (e) { return _this.configuratorStore.dispatch({
                    type: 'SET_LW_NO_OF_SPOKES',
                    variant: e.$delegate.value
                }); }
            }
        ];
        this.$configurator = $configurator;
        if (this.$configurator !== null) {
            var $configuratorJson = domhelpers_shared_1.$('.configurator__json', this.$configurator);
            this.$configuratorWrapper = domhelpers_shared_1.$('.configurator__wrapper', this.$configurator);
            if ($configuratorJson !== null && this.$configuratorWrapper !== null) {
                try {
                    this.data = JSON.parse($configuratorJson.innerHTML);
                }
                catch (_) { }
                this.bindEvents();
                this.delegateEvents();
                this.configuratorStore$.subscribe(this.render.bind(this));
            }
        }
    }
    ConfiguratorComponent.prototype.bindEvents = function () {
        var _this = this;
        rxjs_1.fromEvent(this.$configurator, 'load', { capture: true }).subscribe(function () {
            _this.$configurator.classList.remove(_this.LOADING_CLASS);
        });
    };
    ConfiguratorComponent.prototype.delegateEvents = function () {
        var _this = this;
        this.behaviors.forEach(function (behavior) {
            if (behavior.pipe !== null) {
                delegatedevent_shared_1.delegatedEventProxy(_this.$configurator, behavior.event, behavior.selector).pipe(behavior.pipe).subscribe(behavior.action.bind(_this));
            }
            else {
                delegatedevent_shared_1.delegatedEventProxy(_this.$configurator, behavior.event, behavior.selector).subscribe(behavior.action.bind(_this));
            }
        });
    };
    ConfiguratorComponent.prototype.render = function (configurator) {
        morphdom(this.$configuratorWrapper, this.template(this.getTemplateData(configurator)), {
            onBeforeElUpdated: this.onMorphing.bind(this),
            onNodeAdded: this.onMorphing.bind(this)
        });
    };
    ConfiguratorComponent.prototype.onMorphing = function ($from, $to) {
        if ($to === void 0) { $to = null; }
        if (typeof $from.classList !== 'undefined'
            && $from.classList.contains('image__image')
            && $from.hasAttribute('src')
            && ($to === null
                || (typeof $to.classList !== 'undefined'
                    && $to.classList.contains('image__image')
                    && $to.hasAttribute('src')
                    && $from.getAttribute('src') !== $to.getAttribute('src')))) {
            this.$configurator.classList.add(this.LOADING_CLASS);
        }
        if ($to !== null
            && typeof $from.classList !== 'undefined'
            && $from.classList.contains('image__image')) {
            return this.compareLazySizes($from, $to);
        }
        return true;
    };
    ConfiguratorComponent.prototype.compareLazySizes = function ($from, $to) {
        if ($to === void 0) { $to = null; }
        var fromAttrs = Array.from($from.attributes);
        var toAttrs = Array.from($to.attributes);
        return fromAttrs.map(function (a) { return a.nodeName; }).concat(toAttrs.filter(function (a) { return !$from.hasAttribute(a.nodeName); }).map(function (a) { return a.nodeName; })).filter(function (a) { return ['class', 'data-src', 'data-srcset', 'sizes', 'srcset'].indexOf(a) === -1; }).map(function (a) {
            return $from.getAttribute(a) === $to.getAttribute(a);
        }).filter(function (a) { return a === false; }).length > 0;
    };
    ConfiguratorComponent.prototype.getTemplateData = function (configurator) {
        if (this.data === null) {
            return null;
        }
        var variant = this.getFinalVariant(configurator);
        return {
            result: {
                image: this.buildImage(variant),
                headline: this.buildHeadline(variant),
                paragraph: this.buildParagraph(variant),
                small: this.buildSmall(variant)
            },
            form: {
                headline: {
                    text: this.data.locale['form.headline'],
                    level: 2,
                    modifiers: ['mrg-b-8', 'txt-center', 'txt-md-left']
                },
                selects: this.buildSelects(configurator),
                buttons: this.buildButtons(variant)
            }
        };
    };
    ConfiguratorComponent.prototype.getFinalVariant = function (configurator) {
        if (this.data.hasOwnProperty('variants') && Object.keys(this.data.variants).length > 0) {
            var recursion_1 = function (variants) {
                var type = Object.keys(variants)[0];
                var values = Object.keys(variants[type]);
                var current = configurator[type];
                if (values.length > 0 && current !== null) {
                    if (configurator_shared_1.ConfiguratorTypes.indexOf(type) === -1) {
                        return variants;
                    }
                    return recursion_1(variants[type][current]);
                }
                else {
                    return null;
                }
            };
            return recursion_1(this.data.variants);
        }
        return null;
    };
    ConfiguratorComponent.prototype.buildImage = function (variant) {
        if (variant !== null) {
            return __assign({}, variant.image, { alt: variant.name, modifiers: ['mrg-t-12', 'mrg-md-t-0'] });
        }
        return this.data.defaultImage;
    };
    ConfiguratorComponent.prototype.buildHeadline = function (variant) {
        if (variant !== null) {
            return {
                text: variant.name,
                level: 3,
                modifiers: ['mrg-t-13', 'mrg-b-7', 'mrg-lr-0', 'txt-uc']
            };
        }
        return null;
    };
    ConfiguratorComponent.prototype.buildParagraph = function (variant) {
        if (variant !== null
            && this.data.locale.hasOwnProperty('result.price.locale')
            && this.data.locale.hasOwnProperty('result.price.currency')) {
            return {
                text: new Intl.NumberFormat(this.data.locale['result.price.locale'], {
                    style: 'currency',
                    currency: this.data.locale['result.price.currency'],
                    minimumFractionDigits: 0
                }).format(variant.price / 100),
                modifiers: ['mrg-b-2', 'fnt-bk']
            };
        }
        return null;
    };
    ConfiguratorComponent.prototype.buildSmall = function (variant) {
        if (variant !== null
            && this.data.locale.hasOwnProperty('result.small')) {
            return {
                text: this.data.locale['result.small'],
                modifiers: ['col-black', 'mrg-b-0']
            };
        }
        return null;
    };
    ConfiguratorComponent.prototype.buildSelects = function (configurator) {
        var _this = this;
        if (this.data.hasOwnProperty('variants') && Object.keys(this.data.variants).length > 0) {
            var recursion_2 = function (variants, parentActive) {
                var type = Object.keys(variants)[0];
                if (configurator_shared_1.ConfiguratorTypes.indexOf(type) !== -1) {
                    var values = Object.keys(variants[type]);
                    if (values.length > 0) {
                        var current_1 = configurator[type];
                        return [{
                                id: "configurator-" + _this.uuid + "--" + _this.decamelize(type),
                                name: type,
                                label: _this.data.locale["form.label." + type],
                                disabled: parentActive === false && current_1 === null,
                                options: (current_1 === null ? [{
                                        name: _this.data.locale['form.select.empty'],
                                        selected: true,
                                        value: ''
                                    }] : []).concat(values.map(function (value) { return ({
                                    name: _this.data.locale["form.value." + type + "." + value],
                                    selected: String(current_1) === value,
                                    value: value
                                }); })),
                                modifiers: parentActive === true && current_1 === null ? ['select--current'] : []
                            }].concat(recursion_2(variants[type][current_1 !== null ? current_1 : values[0]], current_1 !== null));
                    }
                }
                return [];
            };
            return recursion_2(this.data.variants, true);
        }
        return null;
    };
    ConfiguratorComponent.prototype.buildButtons = function (variant) {
        if (variant !== null) {
            return [
                {
                    text: this.data.locale['buttons.details'],
                    href: variant.links.details,
                    modifiers: ['button--border', 'mrg-t-13', 'mrg-b-4', 'mrg-lr-0', 'txt-uc', 'txt-center']
                },
                variant.links.hasOwnProperty('shop') ? {
                    text: this.data.locale['buttons.buy'],
                    href: variant.links.shop,
                    modifiers: ['button--cta', 'txt-uc', 'txt-center']
                } : {
                    text: this.data.locale['buttons.dealer'],
                    href: this.data.dealer,
                    modifiers: ['button--cta', 'txt-uc', 'txt-center']
                },
                {
                    text: this.data.locale['link.compare'],
                    href: variant.links.compare,
                    modifiers: ['mrg-t-6', 'mrg-b-0', 'mrg-lr-auto', 'col-black', 'txt-uc', 'txt-center']
                }
            ];
        }
        return [];
    };
    ConfiguratorComponent.prototype.decamelize = function (camel) {
        return camel.replace(/([A-Z])/g, function (a) { return "-" + a[0].toLowerCase(); });
    };
    return ConfiguratorComponent;
}());
exports.ConfiguratorComponent = ConfiguratorComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var overlay_service_1 = require("@services/overlay.service");
var OverlayComponent = (function () {
    function OverlayComponent() {
        var _this = this;
        this.active$ = new overlay_service_1.OverlayService().getActive();
        this.$overlay = window.document.createElement('div');
        this.CLASS_OVERLAY = 'overlay';
        this.CLASS_OVERLAY_ACTIVE = 'overlay--active';
        if (OverlayComponent.instance) {
            return OverlayComponent.instance;
        }
        OverlayComponent.instance = this;
        this.$overlay.classList.add(this.CLASS_OVERLAY);
        document.body.appendChild(this.$overlay);
        this.active$.subscribe(function (active) {
            if (active === true) {
                _this.$overlay.classList.add(_this.CLASS_OVERLAY_ACTIVE);
            }
            else {
                _this.$overlay.classList.remove(_this.CLASS_OVERLAY_ACTIVE);
            }
        });
    }
    return OverlayComponent;
}());
exports.OverlayComponent = OverlayComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var SearchComponent = (function () {
    function SearchComponent() {
        this.FOCUS_CLASS = 'search--focussed';
        if (SearchComponent.instance) {
            return SearchComponent.instance;
        }
        SearchComponent.instance = this;
        this.$search = domhelpers_shared_1.$('.search');
        if (this.$search !== null) {
            this.delegateEvents();
        }
    }
    SearchComponent.prototype.delegateEvents = function () {
        this.focus$ = delegatedevent_shared_1.delegatedEventProxy(this.$search, 'focus', '.input__field', { capture: true });
        this.focus$.subscribe(this.handleFocus.bind(this));
        this.blur$ = delegatedevent_shared_1.delegatedEventProxy(this.$search, 'blur', '.input__field', { capture: true });
        this.blur$.subscribe(this.handleBlur.bind(this));
    };
    SearchComponent.prototype.handleFocus = function (e) {
        this.$search.classList.add(this.FOCUS_CLASS);
    };
    SearchComponent.prototype.handleBlur = function (e) {
        setTimeout(function () {
            SearchComponent.instance.$search.classList.remove(SearchComponent.instance.FOCUS_CLASS);
        }, 200);
    };
    SearchComponent.prototype.getFocus = function () {
        return this.focus$;
    };
    SearchComponent.prototype.getBlur = function () {
        return this.blur$;
    };
    return SearchComponent;
}());
exports.SearchComponent = SearchComponent;

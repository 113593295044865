"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var FilterComponent = (function () {
    function FilterComponent() {
        if (FilterComponent.instance) {
            return FilterComponent.instance;
        }
        FilterComponent.instance = this;
        this.$filter = domhelpers_shared_1.$('#filter_container');
        this.$no_results = domhelpers_shared_1.$('#downloads_no_results');
        this.$filter_events = domhelpers_shared_1.$('.main--eventslist');
        if (this.$filter !== null) {
            this.delegateEvents();
        }
        if (this.$filter_events !== null) {
            this.delegateEventsFairs();
        }
    }
    FilterComponent.prototype.delegateEvents = function () {
        this.changeMain$ = delegatedevent_shared_1.delegatedEventProxy(this.$filter, 'change', '.filter_main', { capture: true });
        this.changeMain$.subscribe(this.handleChangeMain.bind(this));
        this.changeSub$ = delegatedevent_shared_1.delegatedEventProxy(this.$filter, 'change', '.filter_sub', { capture: true });
        this.changeSub$.subscribe(this.handleChangeSub.bind(this));
    };
    FilterComponent.prototype.delegateEventsFairs = function () {
        this.redirectEventFilter$ = delegatedevent_shared_1.delegatedEventProxy(this.$filter_events, 'change', '.filter_event', { capture: true });
        this.redirectEventFilter$.subscribe(this.handleRedirectEventFilter.bind(this));
    };
    FilterComponent.prototype.handleChangeMain = function (e) {
        var $select_subfields = domhelpers_shared_1.$$('.filter_sub');
        this.main_filter_id = Number(e.$delegate.value);
        this.sub_filter_id = null;
        $select_subfields.forEach(function ($item) {
            $item.selectedIndex = 0;
            $item.parentElement.classList.add('d-none');
        });
        if (this.main_filter_id) {
            var element = document.getElementById('filter_sub_' + this.main_filter_id);
            element.removeAttribute('disabled');
            element.parentElement.classList.remove('d-none', 'select--disabled');
        }
        else {
            $select_subfields[0].parentElement.classList.remove('d-none');
            $select_subfields.forEach(function ($element) {
                $element.setAttribute('disabled', 'disabled');
                $element.parentElement.classList.add('select--disabled');
            });
        }
        this.filterElements();
    };
    FilterComponent.prototype.handleChangeSub = function (e) {
        this.sub_filter_id = Number(e.$delegate.value);
        this.filterElements();
    };
    FilterComponent.prototype.filterElements = function () {
        var _this = this;
        var $filter_elements = domhelpers_shared_1.$$('.filter-element');
        var $accordion_container = domhelpers_shared_1.$$('.accordion-filter');
        if (this.sub_filter_id || this.main_filter_id) {
            $filter_elements.forEach(function ($item) { return $item.classList.add('d-none'); });
            $accordion_container.forEach(function ($item) { return $item.parentElement.parentElement.classList.add('d-none'); });
            var $elements = domhelpers_shared_1.$$('.filter-element-' + this.main_filter_id);
            $elements.forEach(function ($element) {
                var show = false;
                if (_this.sub_filter_id) {
                    if ($element.classList.contains('filter-element-' + _this.sub_filter_id) ||
                        (!$element.classList.contains('filter-element-' + _this.sub_filter_id) && $element.classList.contains('filter-category'))) {
                        show = true;
                    }
                }
                else {
                    show = true;
                }
                if (show)
                    $element.classList.remove('d-none');
            });
            $accordion_container.forEach(function ($accordion) {
                var showAccordion = false;
                var $questions = $accordion.children;
                if ($questions.length !== $accordion.getElementsByClassName('d-none').length)
                    showAccordion = true;
                if (showAccordion)
                    $accordion.parentElement.parentElement.classList.remove('d-none');
            });
        }
        else {
            $filter_elements.forEach(function ($item) { return $item.classList.remove('d-none'); });
            $accordion_container.forEach(function ($item) { return $item.parentElement.parentElement.classList.remove('d-none'); });
        }
    };
    FilterComponent.prototype.handleRedirectEventFilter = function (e) {
        domhelpers_shared_1.$('#' + e.$delegate.value).click();
    };
    return FilterComponent;
}());
exports.FilterComponent = FilterComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var Instafeed = require('instafeed.js');
var morphdom = require('morphdom');
var InstagramComponent = (function () {
    function InstagramComponent($instagram) {
        var _this = this;
        this.template = require('../../_patterns/02-organisms/00-global/_07-instagram-frontend.hbs');
        this.response$ = new rxjs_1.ReplaySubject(1);
        this.defaultOptions = {
            get: 'user',
            sortBy: 'most-recent',
            resolution: 'low_resolution',
            mock: true,
            success: function (response) { return _this.response$.next(response); }
        };
        this.WRAPPER_SELECTOR = '.instagram__wrapper';
        this.$instagram = $instagram;
        if (this.$instagram !== null) {
            this.$wrapper = domhelpers_shared_1.$(this.WRAPPER_SELECTOR, this.$instagram);
            if (this.$wrapper !== null) {
                this.response$.subscribe(this.render.bind(this));
                this.getOptions();
                new Instafeed(this.options).run();
            }
        }
    }
    InstagramComponent.prototype.getOptions = function () {
        var options = this.$instagram.getAttribute('data-options');
        if (options !== null) {
            this.options = Object.assign({}, this.defaultOptions, JSON.parse(options));
        }
    };
    InstagramComponent.prototype.render = function (response) {
        morphdom(this.$wrapper, this.template({
            options: this.options,
            response: response
        }));
    };
    InstagramComponent.prototype.getResponseReplaySubject = function () {
        return this.response$;
    };
    return InstagramComponent;
}());
exports.InstagramComponent = InstagramComponent;

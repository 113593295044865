"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("hammerjs");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var VideoImageSliderComponent = (function () {
    function VideoImageSliderComponent($videoImageSlider) {
        this.SLIDES_LIST_SELECTOR = '.video-image-slider__slides-list';
        this.SLIDES_ITEM_SELECTOR = '.video-image-slider__slides-item';
        this.SLIDES_ITEM_ACTIVE_CLASS = 'video-image-slider__slides-item--active';
        this.NAV_SELECTOR = '.video-image-slider__nav-item';
        this.NAV_ACTIVE_CLASS = 'video-image-slider__nav-item--active';
        this.NAV_CLASS = 'video-image-slider__nav-item';
        this.CAPTIONS_LIST_SELECTOR = '.video-image-slider__captions-list';
        this.CAPTIONS_ITEM_SELECTOR = '.video-image-slider__captions-item';
        this.CAPTIONS_ITEM_ACTIVE_CLASS = 'video-image-slider__captions-item--active';
        this.VIDEO_SELECTOR = '.video__iframe';
        this.current$ = new rxjs_1.BehaviorSubject([0, 0]);
        this.decrement$ = new rxjs_1.Subject();
        this.increment$ = new rxjs_1.Subject();
        this.leftSwipe$ = new rxjs_1.Subject();
        this.rightSwipe$ = new rxjs_1.Subject();
        this.$videoImageSlider = $videoImageSlider;
        if (this.$videoImageSlider !== null) {
            this.$slidesList = domhelpers_shared_1.$(this.SLIDES_LIST_SELECTOR, this.$videoImageSlider);
            this.$slidesItems = domhelpers_shared_1.$$(this.SLIDES_ITEM_SELECTOR, this.$videoImageSlider);
            this.$navItems = domhelpers_shared_1.$$(this.NAV_SELECTOR, this.$videoImageSlider);
            this.$captionsList = domhelpers_shared_1.$(this.CAPTIONS_LIST_SELECTOR, this.$videoImageSlider);
            this.$captionsItems = domhelpers_shared_1.$$(this.CAPTIONS_ITEM_SELECTOR, this.$videoImageSlider);
            if (this.$slidesList !== null
                && this.$slidesItems !== null
                && this.$slidesItems.length > 0
                && this.$navItems !== null
                && this.$navItems.length > 0
                && this.$slidesItems.length === this.$navItems.length
                && (this.$captionsList === null
                    || (this.$captionsItems !== null
                        && this.$slidesItems.length === this.$captionsItems.length))) {
                this.subscribeObservables();
                this.delegateEvents();
            }
        }
    }
    VideoImageSliderComponent.prototype.subscribeObservables = function () {
        var _this = this;
        this.current$.subscribe(this.updateSlider.bind(this));
        this.decrement$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.decrease.bind(this));
        this.increment$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.increase.bind(this));
        this.leftSwipe$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.increase.bind(this));
        this.rightSwipe$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.decrease.bind(this));
        var mcHammer = new Hammer(this.$slidesList);
        mcHammer.get('swipe').set({ direction: Hammer.DIRECTION_LEFT + Hammer.DIRECTION_RIGHT });
        rxjs_1.fromEvent(mcHammer, 'swipe')
            .subscribe(function (e) {
            if (e.offsetDirection === Hammer.DIRECTION_LEFT) {
                _this.leftSwipe$.next();
            }
            else if (e.offsetDirection === Hammer.DIRECTION_RIGHT) {
                _this.rightSwipe$.next();
            }
        });
    };
    VideoImageSliderComponent.prototype.delegateEvents = function () {
        this.navClick$ = delegatedevent_shared_1.delegatedEventProxy(this.$videoImageSlider, 'click', this.NAV_SELECTOR);
        this.navClick$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.handleNavClick.bind(this));
    };
    VideoImageSliderComponent.prototype.handleNavClick = function (_a) {
        var _this = this;
        var e = _a[0], current = _a[1];
        this.setCurrent(current, Array.from(e.$delegate.parentNode.children).filter(function ($el) { return $el.classList.contains(_this.NAV_CLASS); }).indexOf(e.$delegate));
    };
    VideoImageSliderComponent.prototype.getCurrentBehaviorSubject = function () {
        return this.current$;
    };
    VideoImageSliderComponent.prototype.getDecrementSubject = function () {
        return this.decrement$;
    };
    VideoImageSliderComponent.prototype.getIncrementSubject = function () {
        return this.increment$;
    };
    VideoImageSliderComponent.prototype.updateSlider = function (current) {
        var _this = this;
        if (current[0] !== current[1]) {
            this.$slidesList.style.transform = "translateX(" + (0 - (current[1] * (100 / this.$slidesItems.length))) + "%)";
            this.$slidesItems.forEach(function ($item) { return $item.classList.remove(_this.SLIDES_ITEM_ACTIVE_CLASS); });
            this.$slidesItems[current[1]].classList.add(this.SLIDES_ITEM_ACTIVE_CLASS);
            this.$navItems.forEach(function ($dot) { return $dot.classList.remove(_this.NAV_ACTIVE_CLASS); });
            this.$navItems[current[1]].classList.add(this.NAV_ACTIVE_CLASS);
            if (this.$captionsList !== null) {
                this.$captionsList.style.transform = "translateX(" + (0 - (current[1] * (100 / this.$captionsItems.length))) + "%)";
                this.$captionsItems.forEach(function ($item) { return $item.classList.remove(_this.CAPTIONS_ITEM_ACTIVE_CLASS); });
                this.$captionsItems[current[1]].classList.add(this.CAPTIONS_ITEM_ACTIVE_CLASS);
            }
        }
    };
    VideoImageSliderComponent.prototype.setCurrent = function (current1, current2) {
        if (current1[1] !== current2) {
            this.current$.next([current1[1], current2]);
        }
    };
    VideoImageSliderComponent.prototype.decrease = function (_a) {
        var _ = _a[0], current = _a[1];
        this.current$.next([current[1], (current[1] - 1) < 0 ? this.$slidesItems.length - 1 : current[1] - 1]);
    };
    VideoImageSliderComponent.prototype.increase = function (_a) {
        var _ = _a[0], current = _a[1];
        this.current$.next([current[1], (current[1] + 1) >= this.$slidesItems.length ? 0 : current[1] + 1]);
    };
    VideoImageSliderComponent.prototype.getNavClick = function () {
        return this.navClick$;
    };
    VideoImageSliderComponent.prototype.getLeftSwipeSubject = function () {
        return this.leftSwipe$;
    };
    VideoImageSliderComponent.prototype.getRightSwipeSubject = function () {
        return this.rightSwipe$;
    };
    return VideoImageSliderComponent;
}());
exports.VideoImageSliderComponent = VideoImageSliderComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var MerchantComponent = (function () {
    function MerchantComponent() {
        this.markers = [];
        this.merchants = [];
        this.lightweightHomeCoordinates = { lat: 47.665325, lng: 9.499266 };
        if (MerchantComponent.instance) {
            return MerchantComponent.instance;
        }
        MerchantComponent.instance = this;
        this.$merchants = domhelpers_shared_1.$('.main__merchants');
        if (this.$merchants !== null) {
            __cmp("addEventListener", ["consent", MerchantComponent.instance.checkConsent, false], null);
        }
    }
    MerchantComponent.prototype.checkConsent = function () {
        var result = __cmp('getCMPData');
        if (result.vendorConsents.s1104 === true) {
            document.querySelector('#googlemaps-js').addEventListener('load', function () {
                MerchantComponent.instance.init();
                document.getElementById('merchants__message').classList.add('d-none');
            });
        }
        else {
            document.getElementById('merchants__message').classList.remove('d-none');
        }
    };
    MerchantComponent.prototype.init = function () {
        this.initMap();
        this.autocomplete.addListener('place_changed', function () {
            var place = MerchantComponent.instance.autocomplete.getPlace();
            MerchantComponent.instance.place = place;
            if (place.geometry) {
                document.getElementById('search-merchants').removeAttribute('disabled');
                document.getElementById('no-results').classList.add('d-none');
            }
            else {
                document.getElementById('search-merchants').setAttribute('disabled', 'disabled');
            }
        });
        if (this.urlPlaceId) {
            this.setPlace(this.urlPlaceId);
        }
        this.addressinput$ = delegatedevent_shared_1.delegatedEventProxy(this.$merchants, 'click', '#search-merchants', { capture: true });
        this.addressinput$.subscribe(this.getMerchantList.bind(this));
        this.merchantDetails$ = delegatedevent_shared_1.delegatedEventProxy(domhelpers_shared_1.$('#merchants__accordion'), 'click', '.merchant__details', { capture: true });
        this.merchantDetails$.subscribe(this.showMerchantOnMap.bind(this));
        this.lightweightHome$ = delegatedevent_shared_1.delegatedEventProxy(domhelpers_shared_1.$('#map__overlay__container'), 'click', '#lightweight__home', { capture: true });
        this.lightweightHome$.subscribe(this.showLightweightHome.bind(this));
    };
    MerchantComponent.prototype.initMap = function () {
        var _this = this;
        this.map = new google.maps.Map(document.getElementById('gmap'), {
            center: this.lightweightHomeCoordinates,
            zoom: 9,
            styles: [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#212121"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#212121"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#181818"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#1b1b1b"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#2c2c2c"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#8a8a8a"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#373737"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#3c3c3c"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#4e4e4e"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#3d3d3d"
                        }
                    ]
                }
            ]
        });
        var input = document.getElementById('address-input');
        this.autocomplete = new google.maps.places.Autocomplete(input);
        this.autocomplete.bindTo('bounds', this.map);
        this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
        this.placesService = new google.maps.places.PlacesService(this.map);
        if (typeof URLSearchParams !== 'undefined') {
            this.urlParams = new URLSearchParams(window.location.search);
            this.urlPlaceId = this.urlParams.get('place_id');
        }
        if (!this.urlPlaceId) {
            navigator.geolocation.getCurrentPosition(function (pos) {
                var currentLocation = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
                _this.geocoder = new google.maps.Geocoder();
                _this.geocoder.geocode({ 'location': currentLocation }, function (result, status) {
                    if (result[0]['place_id']) {
                        MerchantComponent.instance.placesService.getDetails({
                            placeId: result[0]['place_id'],
                            fields: ['formatted_address', 'address_components', 'geometry', 'icon', 'name']
                        }, function (place, placeServiceStatus) {
                            MerchantComponent.instance.place = place;
                            if (place.geometry) {
                                document.getElementById('search-merchants').removeAttribute('disabled');
                                document.getElementById('no-results').classList.add('d-none');
                                var input_1 = document.getElementById('address-input');
                                input_1.value = place.formatted_address;
                                document.getElementById('search-merchants').click();
                            }
                            else {
                                document.getElementById('search-merchants').setAttribute('disabled', 'disabled');
                            }
                        });
                    }
                });
            });
        }
    };
    MerchantComponent.prototype.getMerchantList = function (e) {
        document.getElementById('no-results').classList.add('d-none');
        if (MerchantComponent.instance.place) {
            this.sendRequest(MerchantComponent.instance.place.geometry.location.lat(), MerchantComponent.instance.place.geometry.location.lng());
        }
        else {
            alert("Bitte Adresse wählen");
        }
    };
    MerchantComponent.prototype.filterMerchants = function (merchantList, responseHTML) {
        this.merchants = [];
        for (var key in merchantList) {
            switch (merchantList[key].proof) {
                case 0:
                    var category = 'enterprise';
                    break;
                case 1:
                case 9:
                    var category = 'premium';
                    break;
                case 2:
                    var category = 'importeur';
                    break;
                default:
                    var category = 'enterprise';
            }
            var location = new google.maps.LatLng(merchantList[key].latitude, merchantList[key].longitude);
            this.merchants.push([location, category, merchantList[key].name, merchantList[key].street, merchantList[key].zip, merchantList[key].zip, merchantList[key].phone, merchantList[key].country, merchantList[key].id]);
        }
        if (this.merchants.length == 0) {
            document.getElementById('no-results').classList.remove('d-none');
        }
        else {
            this.setMarkers();
            this.buildOverlay(responseHTML);
        }
        document.getElementById('search-merchants').classList.remove('d-none');
        document.getElementById('ajax-loader').classList.add('d-none');
    };
    MerchantComponent.prototype.setMarkers = function ($animate, $highlightedMerchant) {
        if ($animate === void 0) { $animate = true; }
        if ($highlightedMerchant === void 0) { $highlightedMerchant = ''; }
        this.bounds = new google.maps.LatLngBounds();
        while (this.markers.length)
            this.markers.pop().setMap(null);
        var animation = ($animate ? google.maps.Animation.DROP : null);
        this.merchants.forEach(function (merchant, key) {
            var currentMarker = new google.maps.Marker({
                position: merchant[0],
                icon: '/typo3conf/ext/lightweight/Resources/Public/Images/icon-' + merchant[1] + '.png',
                map: MerchantComponent.instance.map,
                animation: animation
            });
            MerchantComponent.instance.bounds.extend(merchant[0]);
            MerchantComponent.instance.markers.push(currentMarker);
            currentMarker.setVisible(true);
            currentMarker.set('id', key);
            if ($highlightedMerchant) {
                if ($highlightedMerchant != merchant[2])
                    currentMarker.setOpacity(0.5);
            }
            currentMarker.addListener('click', function () {
                var id = currentMarker['id'];
                document.getElementById('merchants__accordion-' + id).click();
                document.getElementById('merchants__accordion-' + id).scrollIntoView();
            });
        });
        if (!$highlightedMerchant) {
            var padding = this.getPadding();
            this.map.fitBounds(this.bounds, { left: padding });
            if (this.map.getZoom() > 13)
                this.map.setZoom(13);
        }
    };
    MerchantComponent.prototype.buildOverlay = function (responseHTML) {
        document.getElementById('merchants__found').innerText = this.markers.length.toString();
        document.getElementById('merchants__accordion').innerHTML = responseHTML;
        document.getElementById('map__overlay__container').classList.add('open');
    };
    MerchantComponent.prototype.showMerchantOnMap = function (e) {
        var $siblings = Array.from(e.$delegate.parentElement.parentElement.parentElement.children);
        var $current = e.$delegate.parentElement.parentElement;
        $current.classList.toggle('active');
        $current.classList.remove('inactive');
        if ($current.classList.contains('active')) {
            $siblings.forEach(function ($sibling) {
                if ($sibling != $current) {
                    $sibling.classList.add('inactive');
                    $sibling.classList.remove('active');
                }
            });
            this.map.panTo(this.markers[e.$delegate.getAttribute('data-marker')].position);
            this.map.setZoom(12);
            this.setMarkers(false, e.$delegate.getAttribute('data-name'));
        }
        else {
            $siblings.forEach(function ($sibling) {
                $sibling.classList.remove('inactive');
            });
            var padding = this.getPadding();
            this.map.fitBounds(this.bounds, { left: padding });
            if (this.map.getZoom() > 12)
                this.map.setZoom(12);
            this.setMarkers(false);
        }
    };
    MerchantComponent.prototype.showLightweightHome = function (e) {
        document.getElementById('search-merchants').classList.add('d-none');
        document.getElementById('ajax-loader').classList.remove('d-none');
        var data = new FormData();
        data.append('tx_lightweight_merchantajax[latitude]', this.lightweightHomeCoordinates.lat.toString());
        data.append('tx_lightweight_merchantajax[longitude]', this.lightweightHomeCoordinates.lng.toString());
        data.append('tx_lightweight_merchantajax[radius]', '20');
        data.append('tx_lightweight_merchantajax[dealer]', '');
        data.append('tx_lightweight_merchantajax[proofed]', '');
        data.append('tx_lightweight_merchantajax[importeur]', '');
        data.append('tx_lightweight_merchantajax[home]', 'true');
        var url = document.getElementById('search-merchants').getAttribute('data-url');
        var xhttp = new XMLHttpRequest();
        xhttp.open('POST', url, true);
        xhttp.send(data);
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var response = JSON.parse(this.responseText);
                MerchantComponent.instance.filterMerchants(response[0], response[1]);
            }
        };
    };
    MerchantComponent.prototype.sendRequest = function (lat, lng) {
        document.getElementById('search-merchants').classList.add('d-none');
        document.getElementById('ajax-loader').classList.remove('d-none');
        var filterDealer = document.getElementById('checkbox-dealer').checked;
        var filterProofed = document.getElementById('checkbox-proofed').checked;
        var filterImporteur = document.getElementById('checkbox-importeur').checked;
        var data = new FormData();
        data.append('tx_lightweight_merchantajax[latitude]', lat);
        data.append('tx_lightweight_merchantajax[longitude]', lng);
        data.append('tx_lightweight_merchantajax[radius]', document.getElementById('select_radius').value);
        data.append('tx_lightweight_merchantajax[dealer]', filterDealer.toString());
        data.append('tx_lightweight_merchantajax[proofed]', filterProofed.toString());
        data.append('tx_lightweight_merchantajax[importeur]', filterImporteur.toString());
        data.append('tx_lightweight_merchantajax[home]', 'false');
        var url = document.getElementById('search-merchants').getAttribute('data-url') + '&type=10001';
        var xhttp = new XMLHttpRequest();
        xhttp.open('POST', url, true);
        xhttp.send(data);
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var response = JSON.parse(this.responseText);
                MerchantComponent.instance.filterMerchants(response[0], response[1]);
            }
        };
    };
    MerchantComponent.prototype.setPlace = function (place_id) {
        MerchantComponent.instance.placesService.getDetails({
            placeId: place_id,
            fields: ['formatted_address', 'address_components', 'geometry', 'icon', 'name']
        }, function (place, placeServiceStatus) {
            MerchantComponent.instance.place = place;
            if (place.geometry) {
                document.getElementById('search-merchants').removeAttribute('disabled');
                document.getElementById('no-results').classList.add('d-none');
                var input = document.getElementById('address-input');
                input.value = place.formatted_address;
                document.getElementById('search-merchants').click();
            }
            else {
                document.getElementById('search-merchants').setAttribute('disabled', 'disabled');
            }
        });
    };
    MerchantComponent.prototype.getPadding = function () {
        var padding = document.getElementById('map__overlay__container').clientWidth;
        if (document.documentElement.clientWidth < 768)
            padding = 0;
        return padding;
    };
    return MerchantComponent;
}());
exports.MerchantComponent = MerchantComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var windowresize_service_1 = require("@services/windowresize.service");
var BreakpointChangeService = (function () {
    function BreakpointChangeService() {
        this.resize$ = new windowresize_service_1.WindowResizeService().getResizeObservable();
        if (BreakpointChangeService.instance) {
            return BreakpointChangeService.instance;
        }
        BreakpointChangeService.instance = this;
        this.breakpointChange$ = new rxjs_1.BehaviorSubject(this.getBreakpoint());
        this.resize$.pipe(operators_1.withLatestFrom(this.breakpointChange$)).subscribe(this.update.bind(this));
    }
    BreakpointChangeService.prototype.getBreakpoint = function () {
        return window.getComputedStyle(document.body, ':before').getPropertyValue('content').replace(/[\"\']/g, '');
    };
    BreakpointChangeService.prototype.update = function (_a) {
        var _ = _a[0], recent = _a[1];
        var current = this.getBreakpoint();
        if (recent !== current) {
            this.breakpointChange$.next(current);
        }
    };
    BreakpointChangeService.prototype.getBehaviorSubject = function () {
        return this.breakpointChange$;
    };
    return BreakpointChangeService;
}());
exports.BreakpointChangeService = BreakpointChangeService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var WindowResizeService = (function () {
    function WindowResizeService() {
        if (WindowResizeService.instance) {
            return WindowResizeService.instance;
        }
        WindowResizeService.instance = this;
        this.resize$ = rxjs_1.fromEvent(window, 'resize').pipe(operators_1.throttleTime(0, rxjs_1.animationFrameScheduler));
    }
    WindowResizeService.prototype.getResizeObservable = function () {
        return this.resize$;
    };
    return WindowResizeService;
}());
exports.WindowResizeService = WindowResizeService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var OverlayService = (function () {
    function OverlayService() {
        this.stack = {};
        if (OverlayService.instance) {
            return OverlayService.instance;
        }
        OverlayService.instance = this;
        this.active$ = new rxjs_1.BehaviorSubject(false);
    }
    OverlayService.prototype.getActive = function () {
        return this.active$;
    };
    OverlayService.prototype.setActive = function (name, active) {
        var _this = this;
        if (active === true) {
            if (!Object.keys(this.stack).some(function (a) { return _this.stack[a] === true; })) {
                this.active$.next(active);
            }
            this.stack[name] = active;
        }
        else {
            this.stack[name] = active;
            if (!Object.keys(this.stack).some(function (a) { return _this.stack[a] === true; })) {
                this.active$.next(active);
            }
        }
    };
    return OverlayService;
}());
exports.OverlayService = OverlayService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("hammerjs");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var delegatedevent_shared_1 = require("@shared/delegatedevent.shared");
var domhelpers_shared_1 = require("@shared/domhelpers.shared");
var SliderComponent = (function () {
    function SliderComponent($slider) {
        this.LIST_SELECTOR = '.slider__slides-list';
        this.ITEM_SELECTOR = '.slider__slides-item';
        this.DOT_SELECTOR = '.slider__dots-item';
        this.ITEM_ACTIVE_CLASS = 'slider__slides-item--active';
        this.DOT_ACTIVE_CLASS = 'slider__dots-item--active';
        this.defaultOptions = {
            interval: 10000,
            play: false,
            infinite: true
        };
        this.current$ = new rxjs_1.BehaviorSubject([0, 0]);
        this.decrement$ = new rxjs_1.Subject();
        this.increment$ = new rxjs_1.Subject();
        this.leftSwipe$ = new rxjs_1.Subject();
        this.rightSwipe$ = new rxjs_1.Subject();
        this.$slider = $slider;
        if (this.$slider !== null) {
            this.$list = domhelpers_shared_1.$(this.LIST_SELECTOR, this.$slider);
            this.$items = domhelpers_shared_1.$$(this.ITEM_SELECTOR, this.$slider);
            this.$dots = domhelpers_shared_1.$$(this.DOT_SELECTOR, this.$slider);
            if (this.$list !== null && this.$items.length > 0 && this.$dots.length > 0 && this.$items.length === this.$dots.length) {
                this.getOptions();
                this.subscribeObservables();
                this.delegateEvents();
                this.setPlayInterval();
            }
        }
    }
    SliderComponent.prototype.getOptions = function () {
        var options = this.$slider.getAttribute('data-options');
        if (options !== null) {
            this.options = Object.assign({}, this.defaultOptions, JSON.parse(options));
        }
    };
    SliderComponent.prototype.subscribeObservables = function () {
        var _this = this;
        this.current$.subscribe(this.updateSlider.bind(this));
        this.decrement$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.decrease.bind(this));
        this.increment$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.increase.bind(this));
        this.leftSwipe$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.increase.bind(this));
        this.rightSwipe$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.decrease.bind(this));
        var mcHammer = new Hammer(this.$list);
        mcHammer.get('swipe').set({ direction: Hammer.DIRECTION_LEFT + Hammer.DIRECTION_RIGHT });
        rxjs_1.fromEvent(mcHammer, 'swipe')
            .subscribe(function (e) {
            if (e.offsetDirection === Hammer.DIRECTION_LEFT) {
                _this.leftSwipe$.next();
                _this.setPlayInterval();
            }
            else if (e.offsetDirection === Hammer.DIRECTION_RIGHT) {
                _this.rightSwipe$.next();
                _this.setPlayInterval();
            }
        });
    };
    SliderComponent.prototype.delegateEvents = function () {
        this.dotClick$ = delegatedevent_shared_1.delegatedEventProxy(this.$slider, 'click', this.DOT_SELECTOR);
        this.dotClick$.pipe(operators_1.withLatestFrom(this.current$)).subscribe(this.handleDotClick.bind(this));
    };
    SliderComponent.prototype.handleDotClick = function (_a) {
        var e = _a[0], current = _a[1];
        this.setCurrent(current, Array.from(e.$delegate.parentNode.children).indexOf(e.$delegate));
        this.setPlayInterval();
    };
    SliderComponent.prototype.setPlayInterval = function () {
        var _this = this;
        if (typeof this.playInterval !== 'undefined') {
            window.clearInterval(this.playInterval);
        }
        if (this.options.play === true) {
            this.playInterval = window.setInterval(function (_) {
                _this.increment$.next();
            }, this.options.interval);
        }
    };
    SliderComponent.prototype.getCurrentBehaviorSubject = function () {
        return this.current$;
    };
    SliderComponent.prototype.getDecrementSubject = function () {
        return this.decrement$;
    };
    SliderComponent.prototype.getIncrementSubject = function () {
        return this.increment$;
    };
    SliderComponent.prototype.updateSlider = function (current) {
        var _this = this;
        if (current[0] !== current[1]) {
            this.$list.style.transform = "translateX(" + (0 - (current[1] * (100 / this.$items.length))) + "%)";
            this.$items.forEach(function ($item) { return $item.classList.remove(_this.ITEM_ACTIVE_CLASS); });
            this.$items[current[1]].classList.add(this.ITEM_ACTIVE_CLASS);
            this.$dots.forEach(function ($dot) { return $dot.classList.remove(_this.DOT_ACTIVE_CLASS); });
            this.$dots[current[1]].classList.add(this.DOT_ACTIVE_CLASS);
        }
    };
    SliderComponent.prototype.setCurrent = function (current1, current2) {
        if (current1[1] !== current2) {
            this.current$.next([current1[1], current2]);
        }
    };
    SliderComponent.prototype.decrease = function (_a) {
        var _ = _a[0], current = _a[1];
        this.current$.next([current[1], (current[1] - 1) < 0 ? this.$items.length - 1 : current[1] - 1]);
    };
    SliderComponent.prototype.increase = function (_a) {
        var _ = _a[0], current = _a[1];
        this.current$.next([current[1], (current[1] + 1) >= this.$items.length ? 0 : current[1] + 1]);
    };
    SliderComponent.prototype.getDotClick = function () {
        return this.dotClick$;
    };
    SliderComponent.prototype.getLeftSwipeSubject = function () {
        return this.leftSwipe$;
    };
    SliderComponent.prototype.getRightSwipeSubject = function () {
        return this.rightSwipe$;
    };
    return SliderComponent;
}());
exports.SliderComponent = SliderComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var BodyClickService = (function () {
    function BodyClickService() {
        if (BodyClickService.instance) {
            return BodyClickService.instance;
        }
        BodyClickService.instance = this;
        this.bodyClick$ = rxjs_1.fromEvent(document.body, 'click');
    }
    BodyClickService.prototype.getObservable = function () {
        return this.bodyClick$;
    };
    BodyClickService.prototype.outsideClick = function ($el, callback) {
        var _this = this;
        return this.getObservable().subscribe(function (f) {
            if (!_this.parentsContainEl(f.target, $el)) {
                callback(f);
            }
        });
    };
    BodyClickService.prototype.parentsContainEl = function ($target, $el) {
        return (function parents($node) {
            return (($node.parentElement || $node.parentNode)
                ? parents($node.parentElement || $node.parentNode)
                : []).concat([$node]);
        })($target).filter(function ($node) { return $node === $el; }).length > 0;
    };
    return BodyClickService;
}());
exports.BodyClickService = BodyClickService;

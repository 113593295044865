var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "figure";
},"3":function(container,depth0,helpers,partials,data) {
    return "div";
},"5":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(container.lambda(depth0, depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return " "
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "=\""
    + alias3(alias2((depth0 != null ? depth0.value : depth0), depth0))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return "alt=\""
    + container.escapeExpression(((helper = (helper = helpers.alt || (depth0 != null ? depth0.alt : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"alt","hash":{},"data":data}) : helper)))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable;

  return "<figcaption class=\"image__caption\">"
    + ((stack1 = ((helper = (helper = helpers.caption || (depth0 != null ? depth0.caption : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"caption","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</figcaption>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.caption : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    class=\"image"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.modifiers : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    "
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.attributes : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n>\n    <img class=\"image__image lazyload\"\n        src=\""
    + alias5(((helper = (helper = helpers.src || (depth0 != null ? depth0.src : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"src","hash":{},"data":data}) : helper)))
    + "\"\n        data-src=\""
    + alias5(((helper = (helper = helpers.rias || (depth0 != null ? depth0.rias : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"rias","hash":{},"data":data}) : helper)))
    + "\"\n        data-widths=\"[160,320,480,640,800,960,1120,1280,1440,1600,1760,1920,2080]\"\n        data-sizes=\"auto\"\n        "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.alt : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.image_attributes : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    />\n    "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.caption : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.caption : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n";
},"useData":true});